const colors = {
  bgColor: "#fcfcfc",
  black: "#000000",
  grey: "#999999",
}

const common = {
  hide: "none", //display: none
}

const theme = {
  colors,
  common,
}

export default theme
