import styled from "@emotion/styled"
import React, { useState } from "react"
import logo from "../../images/header-logo.png"
import youtube from "../../images/icon-youtube.png"
import insta from "../../images/icon-instagram.png"

const SideMenu = ({ isOpen, clickMenu }) => {
  const goToSNS = url => window.open(url, "_blank")

  return (
    <Menu toggle={isOpen}>
      <Header>
        <Logo src={logo} alt="header_logo" />
      </Header>
      <List>
        <Item>
          <LinkBtn onClick={clickMenu} href="https://tech.spartacodingclub.kr/">
            <Strong>HOME</Strong>
          </LinkBtn>
        </Item>
        <Item>
          <LinkBtn
            onClick={clickMenu}
            href="https://spartacodingclub.kr/"
            target="_blank"
          >
            스파르타코딩클럽
          </LinkBtn>
        </Item>
        <Item>
          <LinkBtn
            onClick={clickMenu}
            href="https://career.spartacodingclub.kr/"
            target="_blank"
          >
            개발자 채용공고
          </LinkBtn>
        </Item>
        <Divider></Divider>
        <SNSItem>
          <Youtube
            onClick={() => {
              goToSNS("https://www.youtube.com/c/SpartaCodingClub")
            }}
            src={youtube}
            alt="youtube"
          />
          <Insta
            onClick={() => {
              goToSNS("https://www.instagram.com/spartacodingclub")
            }}
            src={insta}
            alt="insta"
          />
        </SNSItem>
      </List>
    </Menu>
  )
}

export default SideMenu

const Menu = styled.aside`
  background-color: white;
  width: 70vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  transition: transform 0.5s;
  transform: ${props =>
    props.toggle ? "translate3d(30vw, 0, 0);" : "translate3d(110vw, 0, 0);"};

  ${({ theme }) => theme.laptop` 
    display: none;
  `};
`

const Header = styled.header`
  height: 64px;
  border-bottom: 1px solid #f1f1f1;
  display: flex;
  align-items: center;

  ${({ theme }) => theme.tablet` 
    height: 72px;
  `};
`

const Logo = styled.img`
  width: 130px;
  height: 15.6px;
  margin-left: 20px;
`

const List = styled.ul`
  list-style: none;
  font-size: 14px;
  font-weight: bold;
  margin-left: 20px;
`

const Item = styled.li`
  margin-top: 20px;
`

const Strong = styled.strong`
  color: #e8344e;
`

const Divider = styled.div`
  width: 18px;
  height: 2px;
  margin-top: 30px;
  background-color: #d4d4d4;
`

const SNSItem = styled.section`
  width: 70px;
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 33.6px;
`

const Youtube = styled.img`
  width: 24px;
  height: 24px;
`
const Insta = styled.img`
  width: 26px;
  height: 22px;
`

const LinkBtn = styled.a`
  text-decoration: none;
  color: black;
`
