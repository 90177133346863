import { ThemeProvider } from "@emotion/react"
import styled from "@emotion/styled"
import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import Footer from "../organisms/Footer"
import Header from "../organisms/Header"
import media from "./media"
import theme from "./theme"

const Layout = ({ children }) => {
  useEffect(() => {
    if (typeof document !== "undefined") {
      const script = document.createElement("script")
      script.src = "https://developers.kakao.com/sdk/js/kakao.js"
      script.async = true

      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    }
  }, [])

  return (
    <ThemeProvider theme={{ ...theme, ...media }}>
      <Header />
      <MainWrapper>{children}</MainWrapper>
      <Footer />
    </ThemeProvider>
  )
}

export default Layout

const MainWrapper = styled.main`
  /* margin-left: 120px; */
  /* max-width: 1440px; */
  width: 100%;
  padding-top: 64px;
  font-family: "Pretendard";
`
