import { css } from "@emotion/react"

const sizes = {
  tablet: 768,
  laptop: 1200,
  desktop: 2560,
}

export default Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media screen and (min-width: ${sizes[label]}px) {
      ${css(...args)};
    }
  `
  return acc
}, {})
