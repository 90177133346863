import styled from "@emotion/styled"
import React, { useState } from "react"

const BurgerMenu = ({ isOpen, clickMenu }) => {
  return (
    <LineMenu onClick={clickMenu}>
      <Line1 toggle={isOpen}></Line1>
      <Line2 toggle={isOpen}></Line2>
      <Line3 toggle={isOpen}></Line3>
    </LineMenu>
  )
}

export default BurgerMenu

const LineMenu = styled.button`
  display: inline-block;
  transition: all 0.4s;
  box-sizing: border-box;
  position: relative;
  width: 20px;
  height: 14px;
  z-index: 25;
  border: none;
  background-color: transparent;

  span {
    display: inline-block;
    transition: all 0.4s;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: black;
    border-radius: 4px;
  }

  ${({ theme }) => theme.laptop` 
    display: none;
  `};
`

const Line1 = styled.span`
  top: 0;
  ${props =>
    props.toggle ? "transform: translateY(6px) rotate(-45deg);" : null}
`
const Line2 = styled.span`
  top: 6px;
  ${props => (props.toggle ? "opacity: 0;" : null)}
`
const Line3 = styled.span`
  bottom: 0;
  ${props =>
    props.toggle ? "transform: translateY(-6px) rotate(45deg);" : null}
`
