import styled from "@emotion/styled"
import React from "react"

const Footer = props => (
  <FooterText>
    <Text>
      Copyright ©2022 <b>TEAMSPARTA.</b> All rights reserved.
    </Text>
  </FooterText>
)

export default Footer

const FooterText = styled.footer`
  width: 100%;
  height: 113px;
  font-family: "Pretendard";
  /* padding: 56px 16px 40px 16px;
  font-size: 12px;
  text-align: left;
  color: #8b8b8b;
  display: flex;
  box-sizing: border-box;

  ${({ theme }) => theme.tablet` 
    padding: 56px 30px 40px 30px;
  `};

  ${({ theme }) => theme.laptop` 
    padding: 56px 144px 40px 144px;
  `}; */
`

const Text = styled.p`
  width: 100%;
  max-width: 1440px;
  padding: 56px 16px 40px 16px;
  font-size: 12px;
  text-align: left;
  color: #8b8b8b;
  display: flex;
  box-sizing: border-box;
  margin: auto;

  ${({ theme }) => theme.tablet` 
    padding: 56px 30px 40px 30px;
  `};

  ${({ theme }) => theme.laptop` 
    padding: 56px 144px 40px 144px;
  `};
`
