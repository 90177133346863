import styled from "@emotion/styled"
import { navigate } from "gatsby-link"
import React, { useState, useEffect, useRef } from "react"
import logo from "../../images/header-logo.png"
import instagram from "../../images/icon-instagram.png"
import youtube from "../../images/icon-youtube.png"
import BurgerMenu from "../atoms/BurgerMenu"
import SideMenu from "./SideMenu"

const throttle = function (callback, waitTime) {
  let timerId = null
  return e => {
    if (timerId) return
    timerId = setTimeout(() => {
      callback.call(this, e)
      timerId = null
    }, waitTime)
  }
}

const Header = props => {
  const [hide, setHide] = useState(false)
  const [pageY, setPageY] = useState(0)
  const [isOpen, setOpen] = useState(false)
  const clickMenu = () => {
    setOpen(!isOpen)
  }

  let realDoc = null
  if (typeof document !== "undefined") {
    realDoc = document
  }
  const documentRef = useRef(realDoc)

  const handleScroll = () => {
    if (!isOpen) {
      const { pageYOffset } = window
      const deltaY = pageYOffset - pageY
      const hide = pageYOffset !== 0 && deltaY >= 0
      setHide(hide)
      setPageY(pageYOffset)
    }
  }

  const throttleScroll = throttle(handleScroll, 50)

  useEffect(() => {
    if (!isOpen) {
      documentRef.current.addEventListener("scroll", throttleScroll)

      return () =>
        documentRef.current.removeEventListener("scroll", throttleScroll)
    }
  }, [pageY, isOpen])

  const goToHome = () => {
    navigate("/")
  }

  const goToSNS = url => window.open(url, "_blank")

  return (
    <Container className={hide && "hide"}>
      <HeaderWrapper>
        <Logo src={logo} alt="header_logo" onClick={goToHome} />
        <BurgerMenu clickMenu={clickMenu} isOpen={isOpen} />
        <SideMenu clickMenu={clickMenu} isOpen={isOpen} />
        {isOpen ? <Background /> : null}
        <Text>
          <LinkWrapper>
            <li>
              <LinkBtn href="https://spartacodingclub.kr/" target="_blank">
                스파르타코딩클럽
              </LinkBtn>
            </li>
            <li>
              <LinkBtn
                href="https://career.spartacodingclub.kr/"
                target="_blank"
              >
                개발자 채용공고
              </LinkBtn>
            </li>
          </LinkWrapper>
          <Devider></Devider>
          <SNSWrapper>
            <li>
              <IconBtn1
                onClick={() => {
                  goToSNS("https://www.youtube.com/c/SpartaCodingClub")
                }}
                src={youtube}
                alt="youtube"
              />
            </li>
            <li>
              <IconBtn2
                onClick={() => {
                  goToSNS("https://www.instagram.com/spartacodingclub")
                }}
                src={instagram}
                alt="instagram"
              />
            </li>
          </SNSWrapper>
        </Text>
      </HeaderWrapper>
    </Container>
  )
}

export default Header

const Container = styled.nav`
  width: 100%;
  height: 64px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  background-color: #fcfcfc;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
  transition: transform 1s;
  font-family: "Pretendard";
  /* padding: 20px 16px; */

  box-sizing: border-box;

  &.hide {
    transform: translateY(-80px);
  }

  ${({ theme }) => theme.tablet` 
    height: 72px;
  `};

  ${({ theme }) => theme.laptop` 
    height: 72px;
  `};
`

const HeaderWrapper = styled.section`
  width: 100%;
  max-width: 1440px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 16px;
  box-sizing: border-box;

  ${({ theme }) => theme.tablet` 
    padding: 24px 30px;
    height: 72px;
  `};

  ${({ theme }) => theme.laptop` 
    padding: 24px 144px;
    height: 72px;
  `};
`

const Logo = styled.img`
  width: 200px;
  height: 24px;
  cursor: pointer;
`

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
`

const Text = styled.section`
  display: none;

  ${({ theme }) => theme.laptop`
    display: flex; 
    list-style: none;
    display: flex;
    align-items: center; 
    font-size: 16px;
`};
`

const LinkWrapper = styled.ul`
  ${({ theme }) => theme.laptop`
    display: flex; 
    width: 322px;
    list-style: none;
    align-items: center; 
`};
`

const LinkBtn = styled.a`
  &:hover {
    color: #e8344e;
    font-weight: bold;
  }
  ${({ theme }) => theme.laptop` 
    margin-right: 40px;
    text-decoration: none;
    color: black;`}
`

const SNSWrapper = styled.ul`
  ${({ theme }) => theme.laptop` 
    width: 128px;
    list-style: none;
    display: flex;
    align-items: center; 
  `};

  li {
    ${({ theme }) => theme.laptop` 
      margin-left: 40px;
      height: 24px;
      align-items: center; 
  `};
  }
`

const Devider = styled.div`
  ${({ theme }) => theme.laptop` 
    display: inherit;
    width: 2px;
    height: 18px;
    border-right: #d4d4d4 2px solid;
  `}
`

const IconBtn1 = styled.img`
  width: 24px;
  height: 24px;
`

const IconBtn2 = styled.img`
  width: 28px;
  height: 24px;
`
